const Pallette = () => {
    return (
        <div>
        <h1 style={{color: "white"}} >This is how you can create your own color pallete from a picture you took. </h1>
        <img
            style={{height: "500px"}} 
            id= 'img' src='images/Projects/Pallette/steps.png' alt='tripImg'></img>

        </div>
    )
}

export default Pallette;